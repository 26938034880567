@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/_tools.typography-mixins.scss";

.TileCard {
	@include paragraph;
	display: flex;
	padding: var(--spacing--5xl) 0;
	border-bottom: 0.1rem solid currentColor;
	flex-direction: column-reverse;
	align-items: center;
	margin: 0;

	@include mq("md") {
		@include paragraph--lg;
		flex-direction: row;
	}

	&___borderNone {
		border: none;
	}

	&___noPaddingTop {
		padding-top: 0;
	}
}

.TileCard_textbox {
	width: 100%;
}

.TileCard_kicker {
	margin-top: var(--spacing--lg);

	@include mq("md") {
		margin-top: 0;
	}
}

.TileCard_heading {
	@include heading--4xl;
	margin-bottom: var(--spacing--lg);
	margin-top: var(--spacing--sm);
	padding-right: 0;

	@include mq("md") {
		margin-bottom: var(--spacing--lg);
		margin-top: var(--spacing--base);
		padding-right: var(--spacing--base);
	}
}

.TileCard_text {
	margin-bottom: var(--spacing--base);
	width: 100%;
	text-wrap: balance;
	padding-right: 0;

	@include mq("md") {
		max-width: 46rem;
		padding-right: var(--spacing--base);
	}
}

.TileCard_media {
	width: 100%;
	flex-shrink: 0;

	@include mq("md") {
		max-width: 29.6rem;
	}
}

.TileCard_mediabox {
	@include aspect-ratio(65, 56);

	@include mq("md") {
		@include aspect-ratio(296, 357);
	}
}

.TileCard_playIconContainer {
	background: none;
	width: var(--spacing--2xl);
	height: var(--spacing--2xl);
	border-radius: 100%;
	position: relative;
}

.TileCard_playIcon {
	width: 0;
	height: 0;
	border-top: var(--spacing--sm) solid transparent;
	border-left: calc(var(--spacing--2xl) / 2) solid #fff;
	border-bottom: var(--spacing--sm) solid transparent;
	position: absolute;
	top: 15%;
	left: 35%;
}

.TileCard_link {
	@include paragraph;

	@include mq("md") {
		@include paragraph--lg;
	}
}

// Theme: Kogp
[data-theme="kogp"] {
	.TileCard_kicker {
		font-weight: var(--font-weight--bold);
		text-transform: uppercase;
	}
	.TileCard_link,
	.TileCard_text a {
		color: var(--color-red-light);
	}
}
